import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dbbb311 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _5f5e28db = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _1c2df8b5 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _1ff4dacf = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _760edf58 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _83c16e1e = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _5c1c8090 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _8fe0e056 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _0a63977b = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _36577c91 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _04d8828a = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _59b66c2c = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _08965a64 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _98cd5224 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _74771eaa = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _5089fbf0 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _366a84d3 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _4d5bbff0 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _4836bcb2 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _0467cf33 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _4493821e = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _2dbbb311,
    name: "kits"
  }, {
    path: "/lojas",
    component: _5f5e28db,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _1c2df8b5,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _1ff4dacf,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _760edf58,
    name: "motos"
  }, {
    path: "/reset",
    component: _83c16e1e,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _5c1c8090,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _8fe0e056,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _0a63977b,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _36577c91,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _04d8828a,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _59b66c2c,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _08965a64,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _98cd5224,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _74771eaa,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _5089fbf0,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _366a84d3,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _4d5bbff0,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _4836bcb2,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _0467cf33,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _4493821e,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
